/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Flex, Text, jsx } from 'theme-ui'

import TextWidthLink from '../TextWidthLink'

const TextBlock = ({
  heading,
  headingAsH1 = false,
  variant = 'heading',
  ...props
}) => (
  <Flex
    sx={{
      flexDirection: 'column',
      justifyContent: 'center',
    }}
  >
    {headingAsH1 ? (
      <h1
        sx={{
          fontFamily: 'body',
          fontWeight: 'extraBold',
          color: 'charcoal',
          fontSize: [6, 6, 7, 7],
        }}
      >
        {heading}
      </h1>
    ) : (
      <Text pb={4} variant={variant}>
        {heading}
      </Text>
    )}
    <TextWidthLink {...props} />
  </Flex>
)

export default TextBlock
