/* eslint-disable react/jsx-pascal-case */
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Flex, Box, Text, jsx, Styled } from 'theme-ui'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { ArrowLeft } from 'iconic-react'

const BackButton = () => {
  const { t } = useTranslation()
  return (
    <Box pt={4} px={[0, 0, 4, 4]}>
      <Flex sx={{ justifyContent: 'space-between' }}>
        <Styled.div
          sx={{
            cursor: 'none',
            '&:hover': {
              opacity: 0.8,
              transition: '.1s ease-in-out',
              cursor: 'pointer',
            },
          }}
          onClick={() => navigate(-1)}
        >
          <Flex
            sx={{
              alignItems: 'center',
              color: 'summerSky',
            }}
          >
            <ArrowLeft size={50} />
            <Text
              sx={{
                fontFamily: 'body',
                fontWeight: 'normal',
                color: 'summerSky',
                fontSize: [3, 4, 4, 4],
                lineHeight: 1,
              }}
            >
              {t('Global:back')}
            </Text>
          </Flex>
        </Styled.div>
      </Flex>
    </Box>
  )
}

export default BackButton
