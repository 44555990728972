/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Text, jsx } from 'theme-ui'

import Link from '../Link'

const TextWidthLink = ({
  textBeforeLink,
  linkText,
  href,
  textAfterLink,
  isInternal = true,
}) => (
  <Text variant="detail">
    <Text variant="detail" sx={{ marginRight: 1 }}>
      {textBeforeLink}
    </Text>
    {href && (
      <>
        <Link
          href={href}
          text={linkText}
          isFooter={false}
          isInternal={isInternal}
        />
        <Text variant="detail" sx={{ marginLeft: 1 }}>
          {textAfterLink}
        </Text>
      </>
    )}
  </Text>
)

export default TextWidthLink
