/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Box, Label, Input, Button, Textarea, jsx } from 'theme-ui'
import { useTranslation } from 'react-i18next'

import SEO from '../components/Seo'
import BackButton from '../components/BackButton'
import PageLayout from '../components/PageLayout'
import TextBlock from '../components/TextBlock'

const Support = () => {
  const { t } = useTranslation()

  const handleSubmit = (e) => {
    e.preventDefault()
    const formData = {
      firstName: e.target.firstName.value,
      lastName: e.target.lastName.value,
      email: e.target.email.value,
      phone: e.target.phone.value,
      message: e.target.message.value,
    }
    console.log(formData)
  }

  return (
    <PageLayout>
      <SEO title="Help & Support" />
      <Box pt={4} px={4}>
        <BackButton />
      </Box>
      <Box p={4}>
        <TextBlock
          heading={t('SupportPage:pageHeading')}
          headingAsH1={true}
          textBeforeLink={t('SupportPage:pageHeadingDetail')}
        />
      </Box>
      <Box p={4} as="form" onSubmit={handleSubmit}>
        <Box
          sx={{
            display: 'flex',
            maxWidth: '840px',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ minWidth: ['100%', '100%', '350px', '400px'] }}>
              <Label htmlFor="firstName">First Name *</Label>
              <Input name="firstName" mb={3} />
            </Box>
            <Box sx={{ minWidth: ['100%', '100%', '350px', '400px'] }}>
              <Label htmlFor="lastName">Last Name *</Label>
              <Input name="lastName" mb={3} />
            </Box>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Label htmlFor="email">Email *</Label>
            <Input name="email" mb={3} />
            <Label htmlFor="phone">Phone *</Label>
            <Input name="phone" mb={3} />
            <Label htmlFor="message">Message *</Label>
            <Textarea name="message" rows={6} mb={3} />
          </Box>
        </Box>
        <Button>Submit</Button>
      </Box>
    </PageLayout>
  )
}

export default Support
