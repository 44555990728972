/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Box, jsx } from 'theme-ui'

import 'typeface-open-sans/index.css'
import './layout.css'
import '../../i18n/i18n'

import NavBar from '../NavBar'
import Footer from '../Footer'

const PageLayout = ({ children }) => (
  <>
    <NavBar />
    <Box bg="lynxWhite50" sx={{ paddingTop: [0, 0, '60px', '60px'] }}>
      {children}
    </Box>
    <Footer />
  </>
)

export default PageLayout
